import request from './request'

const salesHistoryApi = {
    storeSalesByScan(data) {
        return request({
            url: 'sales-histories/store-by-scan',
            method: 'post',
            data: data
        })
    },
    csvDownload(query) {
        return request({
          url: 'sales-histories/export',
          method: 'get',
          params: query,
          responseType: 'blob'
        })
      },
}
export default salesHistoryApi