<template>
    <CRow>
        <CCol col="12" xl="12">
            <CCard>
                <CCardHeader>
                    販売履歴
                </CCardHeader>
                <vue-confirm-dialog></vue-confirm-dialog>

                <CCardBody>
                    <div>
                        <CRow>
                            <CCol sm="4">
                                <CInput
                                        id="startDate"
                                        label="販売日"
                                        type="date"
                                        horizontal
                                        v-model="filterStartDate"
                                        @change="changeStartDate"
                                />
                            </CCol>
                            <div>~</div>
                            <CCol sm="4">
                                <CInput
                                        id="enddate"
                                        type="date"
                                        horizontal
                                        v-model="filterEndDate"
                                        @change="changeEndDate"
                                />
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol sm="4" v-if="userShopId === WAREHOUSUE">
                                <CSelect
                                        id="shop"
                                        label="店舗"
                                        v-model="shopSelected"
                                        :options="shopOptions"
                                        placeholder="選択する"
                                        @change="(e) => selectChangeShop(e)"
                                        horizontal
                                >
                                </CSelect>
                            </CCol>
                            <CCol sm="4" v-else>
                                <CSelect
                                        id="user"
                                        label="販売担当者"
                                        v-model="user_id"
                                        :options="users"
                                        placeholder="選択する"
                                        @change="(e) => selectChangeUser(e)"
                                        horizontal
                                >
                                </CSelect>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol sm="2">
                                <CButton size="sm" color="danger" @click="resetFilterData">取り消す</CButton>
                            </CCol>
                        </CRow>
                    </div>
                    <ModalComponent
                            title="Filter Item Master"
                            color="warning"
                            size="xl"
                            :show.sync="filterModal"
                            :footer=false
                    >
                        <FilterItemMastersComponent
                                @filterData="filterItemMaster"
                        ></FilterItemMastersComponent>
                    </ModalComponent>
                    <CRow class="text-right">
                        <CCol sm="12">
                            <CButton color="primary" class="mr-2" @click="handleCsvDownload">CSVダウンロード</CButton>
                            <CButton color="primary" class="mr-2" @click="handOpenFilter">商品絞り込み</CButton>
                            <CButton v-if="userShopId !== WAREHOUSUE" color="primary" class="mr-2" @click="renderScanQR">販売履歴登録</CButton>
                        </CCol>
                    </CRow>

                    <div v-if="loading">
                        <LoadingCompoment />
                    </div>
                    <div v-else :key="key" class="mt-2">
                        <DataTable
                                hover
                                striped
                                border
                                :items="salesHistories"
                                :fields="fields"
                                :items-per-page="perPage"
                                :active-page="page"
                                :pagination-data="paginationData"
                                @page-change="pageChange"
                                @pagination-change="selectChangePerpage"
                                :pagination="{ doubleArrows: false, align: 'center'}"
                                :items-per-page-select="{
                  label: '表示件数',
                  values: [5, 10, 20, 30, 50, 100],
              }"
                        >
                            <div slot="select-header">
                                <CInputCheckbox
                                        id="checkboxAll"
                                        label=""
                                        class="pb-3"
                                        name="listSelected"
                                        @update:checked="() => handleCheckAll()"
                                        :checked="checkAll"
                                />
                            </div>
                            <td slot="select" slot-scope="{item}">
                                <div>
                                    <CInputCheckbox
                                            id="checkbox"
                                            :key="item.id"
                                            label=""
                                            :value="item.item"
                                            name="listSelected"
                                            @update:checked="() => handleSelect(item)"
                                            :checked="listSelected.includes(item.id)"
                                    />
                                </div>
                            </td>
                             <td slot="item_code" slot-scope="{item}">
                                <div> {{ item.item.item_master.item_code || '' }}</div>
                            </td>
                            <td slot="name" slot-scope="{item}">
                                <div> {{ item.item.item_master.name || '' }}</div>
                            </td>
                            <td slot="size" slot-scope="{item}">
                                <div> {{ item.item.item_master.size.name || '' }}</div>
                            </td>
                            <td slot="color" slot-scope="{item}">
                                <div> {{ item.color_name }} </div>
                            </td>
                            <td slot="price" slot-scope="{item}" class="text-right">
                                <div> {{ item.item_master_price || '' }} </div>
                            </td>
                            <td slot="pp_bs_code" slot-scope="{item}" class="text-right">
                                <div> {{ item.pp_bs_code || '' }} </div>
                            </td>
                            <td slot="is_special" slot-scope="{item}" class="text-right">
                                <div> {{ item.special || '' }} </div>
                            </td>
                            <td slot="shop_name" slot-scope="{item}">
                                <div> {{ item.user.shop.name }} </div>
                            </td>
                            <td slot="user_sold" slot-scope="{item}">
                                <div> {{ item.user.name || ''}} </div>
                            </td>
                            <td slot="action" slot-scope="{item}" class="text-center">
                                <div>
                                    <CButton @click="() => handleConfirmDelete(item)" >[削除]</CButton>
                                </div>
                            </td>
                        </DataTable>
                    </div>
                </CCardBody>
            </CCard>
        </CCol>
    </CRow>
</template>

<script>
    import ResourceApi from '../../api/resourceApi';
    import DataTable from "@/components/DataTable";
    import Vue from "vue";
    import SelectComponent from "@/components/views/SelectComponent";
    import userApi from "@/api/userApi";
    import FilterItemMastersComponent from "@/components/views/FilterItemMastersComponent";
    import ModalComponent from "@/components/views/ModalComponent";
    import {numberWithCommas} from "@/utils/utils";
    import salesHistoryApi from "@/api/salesHistoryApi";
    import {WAREHOUSUE} from "@/utils/constance";


    const apiSalesHistories = new ResourceApi('sales-histories');
    const apiShop = new ResourceApi('shops');

    export default {
        name: 'Users',
        components: {DataTable, SelectComponent, FilterItemMastersComponent, ModalComponent},
        data () {
            return {
                salesHistories: [],
                loading: false,
                checkAll: false,
                listSelected: [],
                paginationData: {},
                page: 1,
                perPage: 30,
                query: {page: this.page, per_page: this.perPage},
                key: Math.random(),
                optionsPerPage: [10, 20, 30, 40, 50, 100],
                fields:  [
                    { key: 'select', label: '' },
                    { key: 'item_id', label: '商品ID' },
                    { key: 'item_code', label: '品番' },
                    { key: 'name', label: '品名' },
                    { key: 'size', label: 'サイズ' },
                    { key: 'color', label: '色' },
                    { key: 'price', label: '販売価格'},
                    { key: 'pp_bs_code', label: 'PP/BS'},
                    { key: 'is_special', label: '優待'},
                    { key: 'shop_name', label: '店舗名'},
                    { key: 'user_sold', label: '販売担当者'},
                    { key: 'sold_at', label: '販売日'},
                    { key: 'action', label: '' },
                ],
                activePage: 1,
                filterStartDate: '',
                filterEndDate: '',
                user_id: '',
                users: [],
                filterModal: false,
                sizeId: '',
                itemMaster: {},
                userShopId: this.$store.getters.user
                    ? this.$store.getters.user.shop_id
                    : "",
                WAREHOUSUE: WAREHOUSUE,
                shopOptions: [],
                shopSelected: '',
            }
        },
        watch: {
            listSelected: function(newVal, oldVal) {
                let checkAll = true;
                this.salesHistories.map(s => {
                    if(!newVal.includes(s.id)){
                        checkAll = false;
                    }
                })
                this.checkAll = checkAll;
            },
            salesHistories: function(newVal, oldVal) {
                let checkAll = true;
                this.salesHistories.map(s => {
                    if(!this.listSelected.includes(s.id)){
                        checkAll = false;
                    }
                })
                this.checkAll = checkAll;
            }
        },
        created() {
            this.setInitialDate();
            this.getData(this.getParams());
            this.getUserShop();
            if(this.userShopId === WAREHOUSUE) {
                this.getShops()
            }
        },
        methods: {
            setInitialDate() {
                let fisrtDay = new Date();
                fisrtDay.setDate(1);

                let lastDay = new Date();
                lastDay.setMonth(lastDay.getMonth() + 1);
                lastDay.setDate(0);

                this.filterStartDate = fisrtDay.toISOString().slice(0,10)
                this.filterEndDate = lastDay.toISOString().slice(0,10)
            },
            getShops () {
                this.loading = true
                apiShop.all().then(response => {
                    let shops = [];
                    shops.push({label: '全て', value: ''})
                    response.data.data.map(s =>{
                    shops.push({label: s.name, value: s.id})
                    })
                    this.shopOptions = shops;
                    this.loading = false
                }).catch(error => {
                    this.loading = false
                })
            },
            selectShopChange (val) {
                this.shopSelected =  val.target.value  ? parseInt(val.target.value) : ''
                console.log(this.shopSelected)
            },
            getUserShop() {
                this.loading = true
                userApi.getUserShop().then(response => {
                    this.users = response.data;
                    this.users.forEach(user => {
                        user.label = user.name
                        user.value = user.id
                    })
                }).catch(error => {
                }).finally(() =>{
                    this.loading = false
                })
            },
            getData (params) {
                // this.resetSelected()
                this.loading = true
                this.key =  Math.random()
                this.query ={...this.query, ...params }
                apiSalesHistories.index(this.query).then(response => {
                    // console.log(response.data)
                    // this.salesHistories = response.data.data;
                    let salesHistories = response.data.data;
                    salesHistories.map(salesHistory => {
                        salesHistory.item_master_price = numberWithCommas(salesHistory.price)
                        salesHistory.color_name = salesHistory.item.item_master.color.color_code + ':' + salesHistory.item.item_master.color.name
                    })
                    this.salesHistories = salesHistories;
                    this.paginationData = response.data;
                    this.loading = false
                }).catch(error => {
                    this.loading = false
                })
            },
            handleCheckAll() {
                let listSelected = this.listSelected
                if(this.checkAll){
                    this.salesHistories.map(s => {
                        listSelected = listSelected.filter(l => s.id !== l)
                    })
                }else {
                    this.salesHistories.map(s => {
                        listSelected.push(s.id)
                    })
                }
                this.listSelected = listSelected;
            },
            handleSelect(item){
                if(this.listSelected.includes(item.id)){
                    this.listSelected = this.listSelected.filter(s => s !== item.id)
                }else {
                    this.listSelected.push(item.id)
                }
            },

            handleConfirmDelete(salesHistory){
                let message = '販売履歴からこの商品を削除します。宜しいでしょうか?'
                this.$confirm(
                    {
                        title: '確認',
                        message: message,
                        button: {
                            no: 'いいえ',
                            yes: 'はい'
                        },
                        callback: confirm => {
                            if (confirm) {
                                // ... do something
                                // console.log(salesHistory)
                                this.handleDeleteSalesHistory(salesHistory)
                            }
                        }
                    }
                )
            },

            handleDeleteSalesHistory(salesHistory){
                apiSalesHistories.delete(salesHistory).then(response => {
                    Vue.$toast.success('販売済み商品を未販売にして在庫に戻しました。');
                    this.getData();
                }).catch(error => {
                    // Vue.$toast.error('Error')
                }).finally(() =>{
                    this.loading = false
                })
            },

            selectChange (val) {
                this.statusSelected =  val.target.value  ? parseInt(val.target.value) : ''
                // this.$router.push({ query: { page: val }})
            },
            selectChangePerpage (val) {
                this.perPage =  val  ? parseInt(val) : ''
                this.getData({per_page: val});
            },

            pageChange (val) {
                this.page = val;
                this.getData({page: val});
            },

            getParams() {
                return {
                    sold_at_between: [this.filterStartDate, this.filterEndDate],
                    sales_user_id_equal: this.user_id || null,
                    shop_id_equal: this.shopSelected || null,
                    size_id: this.itemMaster && this.itemMaster.size_id ? this.itemMaster.size_id : '' ,
                    color_id: this.itemMaster && this.itemMaster.color_id ? this.itemMaster.color_id : '',
                    season_id: this.itemMaster && this.itemMaster.season_id ? this.itemMaster.season_id : '',
                    month: this.itemMaster && this.itemMaster.month ? this.itemMaster.month : '',
                    business_year: this.itemMaster && this.itemMaster.business_year ? this.itemMaster.business_year : '',
                    ppbs: this.itemMaster && this.itemMaster.ppbs ? this.itemMaster.ppbs : '',
                    major_category: this.itemMaster && this.itemMaster.major_category ? this.itemMaster.major_category : '',
                    middle_category: this.itemMaster && this.itemMaster.middle_category ? this.itemMaster.middle_category : '',
                    minor_category: this.itemMaster && this.itemMaster.minor_category ? this.itemMaster.minor_category : '',
                }
            },
            changeStartDate() {
                let params = this.getParams()
                this.getData(params)
            },
            changeEndDate() {
                let params = this.getParams()
                this.getData(params)
            },
            selectChangeUser(val) {
                this.user_id = parseInt(val.target.value)
                let params = this.getParams()
                this.getData(params)
            },
            selectChangeShop(val) {
                this.shopSelected = parseInt(val.target.value)
                let params = this.getParams()
                this.getData(params)
            },
            resetFilterData() {
                this.user_id = '';
                this.shopSelected = '';
                this.setInitialDate();
                this.itemMaster= {};
                let params = this.getParams();
                this.getData(params);
            },
            handOpenFilter() {
                this.filterModal = true
            },
            filterItemMaster(val) {
                this.itemMaster = val
                setTimeout(() => {
                    let params = this.getParams()
                    this.getData(params)
                }, 50)
                this.filterModal = false
            },
            renderScanQR() {
                this.$router.push({path: `sales-history/scan-qr`})
            },
            handleCsvDownload() {
                this.loading = true
                let list = this.listSelected
                let listStr = list.join()
                salesHistoryApi.csvDownload({list_id_str: listStr}).then(response => {
                    Vue.$toast.success('ダウンロード成功')
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    let timeCurrent = new Date();
                    fileLink.setAttribute('download', 'sales-history-' + timeCurrent.toLocaleString() + '.csv');
                    document.body.appendChild(fileLink);
                    fileLink.click();
                }).catch(error => {
                    Vue.$toast.error('ダウンロードに失敗しました')
                }).finally(() => {
                    this.loading = false
                })
            }
        }
    }
</script>
