<template>
    <div class="mb-3 bg-gray-100 p-2">
        <CRow>
            <CCol sm="4">
                <SelectComponent id="size" label="サイズ" end-point="sizes" @change="changeSize"
                                 :default-value="sizeId" placeholder="選択する"/>
            </CCol>
            <CCol sm="4">
                <SelectComponent id="color" label="色" :default-options="colorOptions" @change="changeColor"
                                 :default-value="colorId" placeholder="選択する"/>
            </CCol>
            <CCol sm="4">
                <SelectComponent id="season" label="シーズン" end-point="seasons" @change="changeSeasons"
                                 :default-value="seasonId" placeholder="選択する"/>
            </CCol>
            <CCol sm="4">
                <SelectComponent id="month" label="展開月" :default-options="businessMonthOptions"
                                 @change="changeBusinessMonth" :default-value="businessMonth"
                                 placeholder="選択する"/>
            </CCol>
            <CCol sm="4">
                <SelectComponent id="year" label="年度" :default-options="businessYearOptions"
                                 @change="changeBusinessYear" :default-value="businessYear"
                                 placeholder="選択する"/>
            </CCol>
            <CCol sm="4">
                <SelectComponent id="ppbs" label="PP/BS" :default-options="ppbsOptions" @change="changePPBS" :default-value="ppbs" placeholder="選択する"/>
            </CCol>
          <CCol sm="4">
            <SelectComponent id="majorcategory" label="大分類" end-point="major-categories" @change="changeMajorCategory"
                             :default-value="majorCategoryId" placeholder="選択する"
            />
          </CCol>
          <CCol sm="4">
            <SelectComponent id="middlecategory" label="中分類" end-point="middle-categories" @change="changeMiddleCategory"
                             :default-value="middleCategoryId" placeholder="選択する"
            />
          </CCol>
          <CCol sm="4">
            <SelectComponent id="minorcategory" label="小分類" end-point="minor-categories" @change="changeMinorCategory"
                             :default-value="minorCategoryId" placeholder="選択する"
            />
          </CCol>
        </CRow>
<!--        <CategoryComponent :key="key" :default-value="categories" @change="changeCategories"-->
<!--                           placeholder="選択する"/>-->
        <CRow class="mb-3">
            <CCol sm="2">
                <CButton color="primary" class="mr-2" @click="filterData">検索</CButton>
                <CButton color="danger" @click="resetFilterData">取り消す</CButton>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import SelectComponent from "@/components/views/SelectComponent";
    import { setOptionBusinessMonth, setOptionBusinessYear, setOptionColors} from "@/utils/utils";


    export default {
        name: "FilterItemMastersComponent",
        components: { SelectComponent },
        props: {

        },
        data() {
            return {
                businessMonth: '',
                businessYear: '',
                majorCategoryId: '',
                middleCategoryId: '',
                minorCategoryId: '',
                businessYearOptions: [],
                businessMonthOptions: [],
                colorOptions: [],
                seasonId: '',
                colorId: '',
                sizeId: '',
                ppbs: '',
                ppbsOptions: [
                    {label: '選択する', value: ''},
                    {label: 'PP', value: 'PP'},
                    {label: 'BS', value: 'BS'},
                ],
            }
        },

        created() {
            this.businessYearOptions = setOptionBusinessYear();
            this.businessMonthOptions = setOptionBusinessMonth();
            this.colorOptions = setOptionColors();
        },

        methods: {
            changeSeasons(val) {
                this.seasonId = val ? parseInt(val) : ''
                this.$emit('change-season', this.season)
            },
            changeSize(val) {
                this.sizeId = val ? parseInt(val) : ''
                this.$emit('change-size', this.sizeId)
            },
            changeColor(val) {
                this.colorId = val ? parseInt(val) : ''
                this.$emit('change-color', this.colorId)
            },
            changeBusinessYear(val) {
                this.businessYear = val ? parseInt(val) : ''
                this.$emit('change-year', this.businessYear)
            },
            changeBusinessMonth(val) {
                this.businessMonth = val ? parseInt(val) : ''
                this.$emit('change-month', this.businessMonth)
            },
            changeMajorCategory(val) {
                this.majorCategoryId = val ? parseInt(val) : ''
                this.$emit('change-major-category', this.majorCategoryId)
            },
            changeMiddleCategory(val) {
                this.middleCategoryId = val ? parseInt(val) : ''
                this.$emit('change-middle-category', this.middleCategoryId)
            },
           changeMinorCategory(val) {
                this.minorCategoryId = val ? parseInt(val) : ''
                this.$emit('change-minor-category', this.minorCategoryId)
           },

            changePPBS(val) {
                this.ppbs = val
            },
            resetFilterData() {
                this.sizeId = '';
                this.colorId = '';
                this.seasonId = '';
                this.businessMonth = '';
                this.businessYear = '';
                this.majorCategoryId = '';
                this.middleCategoryId = '';
                this.minorCategoryId = '';
                this.ppbs = '';
            },
            filterData() {
                let params = {
                    size_id: this.sizeId,
                    color_id: this.colorId,
                    season_id: this.seasonId,
                    month: this.businessMonth,
                    business_year: this.businessYear,
                    // categories: this.categories,
                    major_category:  this.majorCategoryId,
                    middle_category:  this.middleCategoryId,
                    minor_category:  this.minorCategoryId,
                    ppbs: this.ppbs,
                }
                this.$emit('filterData', params)
            }
        }

    }
</script>

<style scoped>

</style>