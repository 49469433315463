<template>
  <CSelect
      :id="id"
      :label="label"
      horizontal
      v-model="defaultValue"
      :options="options"
      @change="selectChange"
      :placeholder="placeholder"
      :invalid-feedback="isError ? isError : ''"
      :is-valid="isError ? false : null"
  />
</template>

<script>

import ResourceApi from "@/api/resourceApi";

export default {
name: "SelectComponent",
  props: {
    endPoint: String,
    placeholder: String,
    label: String,
    id: String,
    defaultValue: String,
    defaultOptions: {
      type:Array,
      default: () => {return []}
    },
    isError: String
  },
  data() {
      return {
        options: this.defaultOptions || []
      }
    },
  watch: {

  },
  created(){
    this.getAllData();
  },
  methods: {
      selectChange (val) {
        this.$emit('change', val.target.value)
      },
    getAllData () {
      if(!this.endPoint){
          return;
        }
      const api = new ResourceApi(this.endPoint);

      this.loading = true
      api.all().then(response => {
        // console.log(response.data)
        let options = [{
          label: '選択する',
          value: ''
        }];
        response.data.data.map(s =>{
          options.push({label: s.name, value: s.id})
        })
        this.options = options;
        this.loading = false
      }).catch(error => {
        this.loading = false
      })
    },
    }
}
</script>

<style scoped>

</style>